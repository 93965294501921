import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import ibironke from '../../assets/images/departments/ana/ibironke.png';
import adesida from '../../assets/images/departments/ana/adesida.png';
import agbamu from '../../assets/images/departments/ana/agbamu.png';
import alabi from '../../assets/images/departments/ana/alabi.png';
import asiyanbi from '../../assets/images/departments/ana/asiyanbi.png';
import dada from '../../assets/images/departments/ana/dada.png';
import motunrayo from '../../assets/images/departments/ana/motunrayo.png';
import ohagwu from '../../assets/images/departments/ana/ohagwu.png';
import olatosi from '../../assets/images/departments/ana/olatosi.png';
import oyabola from '../../assets/images/departments/ana/oyabola.png';
import rotimi from '../../assets/images/departments/ana/rotimi.png';
import uzoma from '../../assets/images/departments/ana/uzoma.png';
import akanmu from '../../assets/images/departments/ana/akanmu.png';
import { left, right } from '@popperjs/core';

const servicom = () => {
  const staffs = [
    {
      name: 'Mr. Oladimeji Ayobami Adetunji',
    },
    {
      name: 'Miss. Kafayat Olayemi Jinadu',
    },
    {
      name: 'Mr. Chibuike Bright Ibeh',
    },
  ];

  const services = [
    {
      name:
        'Charter development. ',
    },
    {
      name:
        'Documentation, investigation and analyzing complaints from internal and external customers.',
    },
    { name: 'Public awareness.' },
    {
      name:
        'Crowd/Traffic Control',
    },

    {
      name:
        'Customer satisfaction survey',
    },
    {
      name:
        'Quality assurance',
    },
    { name: 'Service assessment checks' },
    {
      name:
        'Inspections (healthcare waste management systems)',
    },
    {
        name:
          'Service monitor/punctuality index.',
      },
      {
        name:
          'Activity check and compound observation',
      },
      {
        name:
          'Monitoring and evaluation of service delivery',
      },
      {
        name:
          'Stakeholders’ meetings',
      },
  ];
  const servicesStandard = [
    {
      name:
        'Ensure all patients and visitors to the hospital receive courteous and prompt attention from staff and officials of the hospital in the use of its various services. ',
    },
    { name: 'To make sure only trained and qualified staff and professionals attends to patients in every Department of the Hospital.' },
    // {
    //   name:
    //     'Crowd/Traffic Control',
    // },
  ];
  const nonRows = staffs.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
    </tr>
  ));
  return (
    <Layout pageTitle='Department of Anaesthesia | LUTH'>
      <NavOne />
      <PageHeader title='Servicom Unit' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>SERVICOM UNIT</h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        On assumption of duty in October 2006, Prof Akin
                        Osibogun, the newly appointed Chief Medical Director of
                        LUTH, directed a reconstitution of the LUTH SERVICOM
                        Committee. This Committee had been ably headed by Dr
                        Kemi Longe-Peters until she was appointed the Director
                        of the National SERVICOM Office in Abuja. The
                        Committee’s mandate was to implement the Tertiary Health
                        Institutions Service Delivery and Quality Care as
                        formulated by the Federal Ministry of Health and
                        positively impact the LUTH workforce to achieve
                        sustainable quality assurance. At a meeting in January
                        2007, the Prof Christopher Bode, a Consultant Paediatric
                        Surgeon was appointed the SERVICOM Nodal Officer for
                        LUTH. Other members of the Servicom Committee were Mr B
                        Grillo, Head, Corporate Services, Chief Nursing Officer
                        (Mrs) AT Adejugbe, Mrs TO Ladenika (Pharmacy) Mr OS
                        Alabi (Engineering Services) Mr DB Oderinde
                        Environmental Health and Mr U Emekwue (Laboratory
                        Services). The present members of the LUTH Servicom are:
                        Dr AA Roberts Nodal Officer Ms OO Fakeye PRO LUTH Mr
                        Wole Ajayi HOD, Medical Records Engr OS Alabi LUTH
                        Engineering Services DNS OS Adepoju LUTH Nursing
                        Services Mr Doutimiwei CSO LUTH Security Unit DNS Luth
                        Annex Yaba It became apparent that for the Servicom
                        concept to succeed in LUTH, it must be understood and
                        accepted as a user-friendly working tool by all
                        stakeholders. The Committee therefore spent time
                        identifying key interface points between
                        service-providers and clients of LUTH, synthesizing the
                        different requirements of patients and likely
                        bottlenecks where the Servicom Committee and its message
                        might be most useful. Since inception, this model of
                        interaction has been strengthened with constant meetings
                        with departmental heads, members of staff and others to
                        familiarize workers with the compliance requirements of
                        Servicom.
                        <br />
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          Lagos University Teaching Hospital
                        </h2>
                        The Lagos University Teaching Hospital was established
                        in 1962 by an act of Parliament to serve as a teaching
                        hospital and research facility to the newly established
                        College of Medicine of the University of Lagos while
                        providing tertiary medical service to the Lagos Mainland
                        community where it was situated. The pioneering team
                        created a reputable set of sustainable standards in
                        keeping with the Hospital’s Vision of providing
                        excellent tertiary care of international standard. Thus
                        two generations of accomplished specialists played
                        prominent roles in the pursuit of excellence within this
                        great edifice in the sixties and seventies.
                        <br />
                        The LUTH brand had long enjoyed enormous appeal,
                        synonymous with last-resort refuge for patients in
                        extremis. It guaranteed salvage from diverse ailments
                        for a population of over 30 millions within Lagos
                        neighboring states and countries, the largest such
                        clientele-base in West Africa.
                        <br />
                        LUTH accepts more tertiary care-related referrals than
                        any other hospital in Nigeria. When medical specialists
                        brain-drained out of many neighboring states in the
                        mid-80s and early 90s, LUTH remained a bastion of
                        surgical excellence as the hospital remained well
                        staffed and resourced and continued to render
                        qualitative service in all disciplines. The hospital
                        therefore experienced little if any major disruptions in
                        staffing during the tumultuous years of emigration of
                        skilled manpower to the Gulf States.
                        <br />
                        <br />
                        The land and purpose-built infrastructure, earlier
                        earmarked for the Mainland General Hospital, were
                        converted into an enormous estate for the newly
                        established LUTH in 1962. This multi-billion Naira,
                        600-bed facility ranks with the oldest of its kind in
                        this country in size and scope. The likes of such
                        projects not feasible anywhere in West Africa now or in
                        the near future.
                        <br />
                        <br />
                        LUTH has always attracted a large pool of talented
                        specialists in various medical and surgical disciplines,
                        nursing and allied medical professions as well as
                        support staff who provide inpatient care to people
                        requiring close medical monitoring and outpatient care
                        to those in need of treatment but not constant medical
                        attention. They work in a well coordinated manner to
                        provide diagnosis and medical treatment of physical and
                        mental health problems, surgery, rehabilitation, health
                        education programs, nursing and physician training. This
                        is partly because the innate individual and corporate
                        wealth within the city has enabled some degree of
                        patients’ affordability of specialist treatment in both
                        private and public institutions. The Teaching Hospital
                        is sponsored by the Federal Government for both
                        recurrent and capital expenditures through regular
                        subvention thus allowing for discounted pricing of
                        sundry services to patients on who doctors are trained.
                        <br />
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          VISION & MISSION
                        </h2>
                        <b>VISION OF LUTH:</b> Our Vision in LUTH is in line
                        with the Federal Ministry of Health’s stated Vision viz
                        to reduce the morbidity and mortality due to
                        communicable diseases to the barest minimum, reverse the
                        increasing prevalence of non-communicable diseases,
                        meeting global targets on the elimination and
                        eradication of diseases and significantly increase the
                        life expectancy and quality of life of Nigerians.
                        <br /> 
                        <b>LUTH MISSION STATEMENT:</b>{' '}
                        <i>
                          “Our corporate goal, as a foremost tertiary
                          institution of international standard, is to provide
                          patient care, produce medical and health manpower and
                          investigate health-related problems by applying the
                          highest standard of promptness, comprehensiveness and
                          effectiveness. This will be achieved through
                          appropriate communication between staff and patients,
                          their relations, among staff, between LUTH and the
                          public and between LUTH and its professional
                          affiliates. In furtherance of this goal, use will be
                          made of well motivated staff as well as expertise
                          based on the highest skill and experience in an
                          environment that is clean, conducive and
                          patient-friendly” Thus, LUTH has continued to render
                          qualitative tertiary care to a large population while
                          producing world-class medical doctors and other cadres
                          of medical manpower. It is also a foremost medical
                          research centre within West Africa.
                        </i>
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                        <h4>Details of Service Provision and Delivery</h4>
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                      <br />
                      <br />
                      <h4>Service Standard</h4>
                      {servicesStandard.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                      <br />
                      <br />
                      {/* <h4>Detailed complaints/grievance redress mechanism</h4>
                      {servicesStandard.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))} */}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      {/* <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ibironke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Ibironke Desalu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, PG(DA), MD, FWACS, FMCA, FMCEM
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant, Head of Department
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Paediatric Anaesthesia Cleft Anaesthesia
                            Resuscitation
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olatosi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. John Olutola Olatosi (Assoc Prof)</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS, FNAMed
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuro-Anaesthesia Critical Care
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akanmu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olanrewaju Nurudeen Akanmu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, PGDA, MPH, FMCA
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Pain/Regional Anaesthesia Cardiothoracic Anaesthesia
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oyabola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oyebola Olubodun Adekola ( Assoc Prof)</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, M.Sc., FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiothoracic Anaesthesia, Pain Management.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={dada} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr.Olufemi Israel O. Dada</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, DA, (WACS), FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span> Ear,
                            Nose and Throat (ENT) Anaesthesia Intensive care
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adesida} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Adeniyi Abiodun Adesida</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FMCA
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuroanaesthesia, Intensive Care Orthopaedic
                            Anaesthesia
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={agbamu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Pamela Onorame Agbamu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant Special Grade 2
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Obstetrics and Gynaecology Otorhinolaryngology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={rotimi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Muyiwa Kayode Rotimi </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant Special Grade 2
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuroanaesthesia Cardiothoracic Resuscitation
                            Critical Care
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={asiyanbi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Gabriel Kolawole Asiyanbi</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Paediatric Anaesthesia,
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={uzoma} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Uzoma Chinedu Ifezue</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Obstetrics, Critical Care, Regional blocks
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={motunrayo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Motunrayo Adebukunola Oladimeji</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc, MBchB, PGDA, MPH, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Paediatric anaesthesia Otorhinolaryngology
                            Cardiothoracic anaesthesia
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={alabi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olubukola Iretiogo Alabi</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuroanaesthesia, Critical Care
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ohagwu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ijeoma Chinenye Ohagwu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span> Oral
                            & Maxillofacial, Urology, Paediatric anaesthesia
                          </p>
                        </div>
                      </div> */}
                      {/* <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Non Academic Staff
                      </h2> */}
                      {/* <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                          </tr>
                        </thead>
                        <tbody>{nonRows}</tbody>
                      </Table> */}
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Get in Touch</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:servicom@luth.gov.ng'>
                          servicom@luth.gov.ng
                        </a>
                        <br />
                        Servicom Desk Officer: 0803 648 9497
                        <br />
                        Nodal Officer: 0705 259 9941 <br /> &nbsp;
                      </p>
                    </React.Fragment>
                  </div>
                  <div label='Client Access'>
                    <React.Fragment>
                      <section className='contact-info-one'>
                        <div className='container'>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <div className='contact-info-one__single'>
                                <div className='contact-info-one__icon'>
                                  <i className='kipso-icon-health'></i>
                                </div>
                                <h2 className='contact-info-one__title'>
                                  CLIENTS’ ACCESS TO LUTH FACILITIES & SERVICES
                                </h2>
                                <p
                                  className='contact-info-one__text'
                                  style={{
                                    marginLeft: 25,
                                    marginRight: 25,
                                  }}
                                >
                                  <strong>Emergencies </strong>
                                  <br />
                                  Patients may seek emergency Obstetrics and
                                  Gynaecological, Medical and Surgical services
                                  at the well manned Accidents & Emergency (A&E)
                                  Centre located at the main gate of LUTH on a
                                  24 hour basis. Emergency care of children is
                                  performed at the Olikoye Ransome-Kuti
                                  Children’s Emergency Room (CHER) near the
                                  Mushin gate. All emergencies are seen with or
                                  without referral. Elective Cases Non-emergency
                                  cases (Electives) are seen on referral at the
                                  various Out-Patient Clinics located on the
                                  first, second and third floors of the A&E
                                  Building. Dental Surgery and Maxillo-facial
                                  cases are attended to at the School of
                                  Dentistry, near the Mushin Gate while
                                  Dermatology and Psychiatry out-patient Clinics
                                  are held at the LUTH Annex premises on Harvey
                                  Road, Yaba Lagos. Information on specific
                                  out-patient clinic days are obtainable at
                                  information desks on the ground floor of each
                                  building. LUTH also runs a model Primary
                                  Health Care Centre at Pakoto in Ogun State
                                  catering for the primary medical needs of a
                                  suburban community in Ifo Local Government
                                  area of Ogun State.
                                </p>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className='contact-info-one__single'>
                                {/* <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div> */}
                                <div
                                  style={{
                                    marginLeft: 50,
                                    marginRight: 100,
                                  }}
                                >
                                  <h2 className='contact-info-one__title'>
                                    Clinical Departments & Facilities
                                  </h2>
                                  <table className='servicom-table'>
                                    <tr>
                                      <th>Departments/ Schools</th>
                                      <th>Services</th>
                                    </tr>
                                    <tr>
                                      <td>Medicine</td>
                                      <td>
                                        Cardiology, Neurology, Gastroenterology,
                                        <br />
                                        Haematology, Endocrinology Nephrology,
                                        <br />
                                        Dermatology Respiratory Medicine, Renal
                                        Dialysis
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Obstetrics & Gynaecology</td>
                                      <td>
                                        Antenatal, Perinatal cares, Family
                                        Planning, <br />
                                        Prevention of Maternal to Child
                                        Transmission <br />
                                        of HIV, Oncology & Pathological Studies,
                                        <br />
                                        Ultrasonography & Foetal Monitoring,
                                        <br />
                                        Reproductive Endocrinology & Fertility
                                        <br />
                                        Regulation, Experimental & Maternal
                                        Medicine
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Paediatrics</td>
                                      <td>
                                        Gastroenterology, Oncology, Haematology,{' '}
                                        <br />
                                        Respiratory Medicine, Cardiology
                                        Neurology,
                                        <br />
                                        Nephrology, Neonatology Nutrition,
                                        <br />
                                        Endocrinology
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Surgery</td>
                                      <td>
                                        Orthopaedics & Trauma Care, Urology
                                        Burns & Plastic Surgery,
                                        <br /> Neurosurgery, Paediatric Surgery,
                                        <br /> Ophthalmology,
                                        Otorhinolaryngology,
                                        <br />
                                        General Surgery Prosthesis Surgical
                                        Endoscopy
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Dental Surgery</td>
                                      <td>
                                        Periodontology
                                        <br /> Prosthetic Surgery
                                        <br />
                                        Maxillofacial Surgery <br />
                                        Orthodontics
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Anaesthesia</td>
                                      <td>
                                        Anaesthesia, ICU Care <br />
                                        Pain Management
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Community Medicine</td>
                                      <td>
                                        Reproductive healthcare services <br />
                                        Family Planning
                                        <br /> Child welfare clinic <br />
                                        Endemic disease clinic <br />
                                        Intellectual disabilities clinic <br />
                                        Community Outreach Programmes <br />
                                        Public health education and community
                                        mobilisation services
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Institute of Child Health</td>
                                      <td>
                                        Training of Community Health Officers,
                                        <br />
                                        Primary Health Care Services at Pakoto
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Physiotherapy Staff Clinic</td>
                                      <td>
                                        Physiotherapy, Staff Medical Care <br />
                                        NHIS Services
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Radiodiagnosis</td>
                                      <td>X-Ray & Imaging Services</td>
                                    </tr>
                                    <tr>
                                      <td>Radiotherapy</td>
                                      <td>
                                        Radiotherapy <br /> Oncology
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Morbid Anatomy </td>
                                      <td>
                                        Histopathology, Cytology <br /> Mortuary
                                        Services
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Laboratories</td>
                                      <td>
                                        Chemical Pathology <br /> Haematology{' '}
                                        <br />
                                        Blood banking
                                        <br />
                                        Microbiology
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Pharmacy</td>
                                      <td>Pharmaceutical Services</td>
                                    </tr>
                                    <tr>
                                      <td>Nursing Services</td>
                                      <td>Nursing</td>
                                    </tr>
                                    <tr>
                                      <td>Psychiatry</td>
                                      <td>Psychiatric services</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className='contact-info-one__single'>
                                {/* <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div> */}
                                <div
                                  style={{
                                    marginLeft: 50,
                                    marginRight: 100,
                                  }}
                                >
                                  <h2 className='contact-info-one__title'>
                                    Non - Clinical Departments & Facilities
                                  </h2>
                                  <p>
                                    Many support services ensure the smooth
                                    delivery of patients’ care. These include
                                    the Central Surgical Sterilizing Department
                                    (CSSD), Engineering, Medical Records,
                                    Laundry, Environmental Sanitation, Stores &
                                    Supplies, Orderlies, Maids, Attendants,
                                    Security Section, Finance, Catering and
                                    Dietetics, Public Health, Social Workers,
                                    Corporate Affairs Unit and others. In
                                    addition, LUTH runs a number of schools of
                                    allied medical disciplines. The School of
                                    Nursing and the School of Midwifery have
                                    served not only the needs of Lagos and
                                    environs but many other states as well.
                                    Schools of Post-Basic Nursing in
                                    Paediatrics, A&E Nursing, Theatre Nursing,
                                    are also well established in LUTH. Other
                                    schools include the Schools of Medical
                                    Records, Laboratory Technology, Health
                                    Information and Management etc. <br />
                                    LUTH maintains a 25-bed Guest House for the
                                    use of Guest Physicians and Lecturers who
                                    frequent the hospital for short exchange of
                                    trainers, examinations, conferences and
                                    workshops.
                                  </p>
                                  <h4
                                    style={{
                                      marginLeft: 50,
                                      marginRight: 100,
                                      textAlign: left,
                                    }}
                                  >
                                    Wards{' '}
                                  </h4>
                                  <table
                                    className='servicom-table'
                                    style={{
                                      marginLeft: 50,
                                      marginRight: 100,
                                      textAlign: left,
                                    }}
                                  >
                                    <tr>
                                      <th>Wards</th>
                                      <th>Cases</th>
                                    </tr>
                                    <tr>
                                      <td>
                                        A2 <br />
                                        A3
                                        <br /> A4
                                      </td>
                                      <td>
                                        Male Medical
                                        <br />
                                        Female Medical
                                        <br />
                                        Female Medical{' '}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        B1
                                        <br />
                                        B2
                                        <br />
                                        B3
                                        <br />
                                        B4
                                      </td>
                                      <td>
                                        Female Surgical
                                        <br />
                                        Gynaecology
                                        <br />
                                        Male Surgical
                                        <br />
                                        Male Surgical
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        C1
                                        <br />
                                        C2
                                        <br />
                                        C3
                                        <br />
                                        C4
                                      </td>
                                      <td>
                                        Gynaecology
                                        <br />
                                        Postnatal <br />
                                        Postnatal
                                        <br />
                                        Antenatal
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        D1
                                        <br />
                                        D2
                                        <br />
                                        D3
                                      </td>
                                      <td>
                                        Neonatology
                                        <br />
                                        Paediatrics
                                        <br />
                                        Paediatrics
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        E1
                                        <br />
                                        E2
                                        <br />
                                        E3
                                        <br />
                                        E4
                                        <br />
                                        E5
                                        <br />
                                        E6
                                      </td>
                                      <td>
                                        Psychiatry
                                        <br />
                                        Male Surgical <br />
                                        Female Surgical
                                        <br />
                                        Paediatric Surgery
                                        <br />
                                        Male Medical
                                        <br />
                                        Male Surgical
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className='contact-info-one__single'>
                                {/* <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div> */}
                                <div
                                  style={{
                                    marginLeft: 50,
                                    marginRight: 100,
                                  }}
                                >
                                  <h2 className='contact-info-one__title'>
                                    SERVICE PROVISION & DELIVERY
                                  </h2>
                                  <div
                                    style={{
                                      textAlign: left,
                                    }}
                                  >
                                    <p>
                                      All the above-stated services shall be
                                      offered to all who seek them within the
                                      walls of LUTH. Unless otherwise directed
                                      by the Hospital Management, no patients
                                      must be turned back without treatment and
                                      sympathetic attention by a senior member
                                      of the team to which the patient is
                                      referred. The standard of care given shall
                                      at all times be comparable to the best
                                      available in any tertiary health care
                                      centre in West Africa. All patients have
                                      the right to be served right and reserve
                                      the right to lodge a formal complaint if
                                      they do no receive service as outlined in
                                      this Charter.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='col-lg-4'>
                          <div className='contact-info-one__single'>
                            <div className='contact-info-one__icon'>
                              <i className='kipso-icon-email'></i>
                            </div>
                            <h2 className='contact-info-one__title'>
                              For Complaints
                            </h2>
                            <p className='contact-info-one__text'>
                              servicom@luth.gov.ng
                              <br />
                              Servicom Desk Officer: 0803 648 9497
                              <br />
                              Nodal Officer: 0705 259 9941 <br /> &nbsp;
                            </p>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </section>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default servicom;
